<template lang="pug">
	.widget
		el-card.box-card
			el-row(slot="header")
				el-col.text-left(:span="24")
					.d-flex.justify-content-between
						h3
							b Control Group - {{this.type}} {{type === 'Create' ? 'new' : '' }} entity
			el-form(:form="form" )
				el-row.text-left(:gutter="12")
					h5 Basic details
					p Aim of a control group is to use the settings of the group and assign them to a brand or an integration and change the settings with ease. The settings include the working hours that can be set in a form of a grid of hours for each day of the week, the brands and integrations that are assigned to the group and the geos that are assigned to the group.
				el-row(:gutter="12")
					el-col(:xs="24", :sm="12" :md="6")
						el-form-item(label="Name" v-model="form.name" :error="errors.name.s ? errors.name.m : ''" v-bind:class="{'is-invalid': errors.name.s}")
							el-input(v-model="form.name" placeholder="Name")

					el-col(:xs="24", :sm="12" :md="6")
						el-form-item(label="Notes" v-model="form.notes" :error="errors.notes.s ? errors.notes.m : ''" v-bind:class="{'is-invalid': errors.notes.s}")
							el-input(v-model="form.notes" placeholder="Notes")


					el-col(:span="24")
						el-form-item(label="Comments" v-model="form.comments" :error="errors.comments.s ? errors.comments.m : ''" v-bind:class="{'is-invalid': errors.comments.s}")
							el-input(type="textarea" :rows="4" v-model="form.comments" placeholder="Name" show-word-limit="" maxlength="4096")

				el-divider
				el-row.text-left(:gutter="12")
					h5 Working Hours
					p Set the working hours and toggle it on or off. If a lead is registered not in the working hours, from his perspective he will be registered on a normal basis (will be redirected to the "thankyou page"), but, will be saved as a failed lead with the failed reason of "out of working hours" and won't send it to the brand.
					p The working hours are set for each day of the week. If you toggle of a day, the brand won't receive leads on that day.
					p.text-success
						i *Note: The timezone of the working hours is the server's timezone which is UTC+3
				el-row(:gutter="12")
					el-col(:xs="12" :sm="6" )
						el-form-item(label="Toggle working hours" position="left")
							el-switch(v-model="form.working_hours_flag" active-text="On" inactive-text="Off" )
				// the table should include a grid of working hours for each day of the week, 0-23, like in controlGroupWorkingHoursItem
				el-table(:data="form.working_hours_list" v-if="form.working_hours_flag" border="" fit="" highlight-current-row="" size="mini")
					el-table-column(align="center" label="Day"  width="120px" fixed="left")
						template(slot-scope="scope")
							| {{ scope.row.label }}
					el-table-column(align="center" label="Toggle" width="150px")
						template(slot-scope="scope")
							el-switch(v-model="scope.row.flag" active-text="On" inactive-text="Off" )
					el-table-column(align="center" label="00:00" min-width="50px")
						template(slot-scope="scope")
							el-checkbox(:disabled="!scope.row.flag" v-model="scope.row.hours[0]" )
					el-table-column(align="center" label="01:00" min-width="50px")
						template(slot-scope="scope")
							el-checkbox(:disabled="!scope.row.flag" v-model="scope.row.hours[1]" )
					el-table-column(align="center" label="02:00" min-width="50px")
						template(slot-scope="scope")
							el-checkbox(:disabled="!scope.row.flag" v-model="scope.row.hours[2]" )
					el-table-column(align="center" label="03:00" min-width="50px")
						template(slot-scope="scope")
							el-checkbox(:disabled="!scope.row.flag" v-model="scope.row.hours[3]" )
					el-table-column(align="center" label="04:00" min-width="50px")
						template(slot-scope="scope")
							el-checkbox(:disabled="!scope.row.flag" v-model="scope.row.hours[4]" )
					el-table-column(align="center" label="05:00" min-width="50px")
						template(slot-scope="scope")
							el-checkbox(:disabled="!scope.row.flag" v-model="scope.row.hours[5]" )
					el-table-column(align="center" label="06:00" min-width="50px")
						template(slot-scope="scope")
							el-checkbox(:disabled="!scope.row.flag" v-model="scope.row.hours[6]" )
					el-table-column(align="center" label="07:00" min-width="50px")
						template(slot-scope="scope")
							el-checkbox(:disabled="!scope.row.flag" v-model="scope.row.hours[7]" )
					el-table-column(align="center" label="08:00" min-width="50px")
						template(slot-scope="scope")
							el-checkbox(:disabled="!scope.row.flag" v-model="scope.row.hours[8]" )
					el-table-column(align="center" label="09:00" min-width="50px")
						template(slot-scope="scope")
							el-checkbox(:disabled="!scope.row.flag" v-model="scope.row.hours[9]" )
					el-table-column(align="center" label="10:00" min-width="50px")
						template(slot-scope="scope")
							el-checkbox(:disabled="!scope.row.flag" v-model="scope.row.hours[10]" )
					el-table-column(align="center" label="11:00" min-width="50px")
						template(slot-scope="scope")
							el-checkbox(:disabled="!scope.row.flag" v-model="scope.row.hours[11]" )
					el-table-column(align="center" label="12:00" min-width="50px")
						template(slot-scope="scope")
							el-checkbox(:disabled="!scope.row.flag" v-model="scope.row.hours[12]" )
					el-table-column(align="center" label="13:00" min-width="50px")
						template(slot-scope="scope")
							el-checkbox(:disabled="!scope.row.flag" v-model="scope.row.hours[13]" )
					el-table-column(align="center" label="14:00" min-width="50px")
						template(slot-scope="scope")
							el-checkbox(:disabled="!scope.row.flag" v-model="scope.row.hours[14]" )
					el-table-column(align="center" label="15:00" min-width="50px")
						template(slot-scope="scope")
							el-checkbox(:disabled="!scope.row.flag" v-model="scope.row.hours[15]" )
					el-table-column(align="center" label="16:00" min-width="50px")
						template(slot-scope="scope")
							el-checkbox(:disabled="!scope.row.flag" v-model="scope.row.hours[16]" )
					el-table-column(align="center" label="17:00" min-width="50px")
						template(slot-scope="scope")
							el-checkbox(:disabled="!scope.row.flag" v-model="scope.row.hours[17]" )
					el-table-column(align="center" label="18:00" min-width="50px")
						template(slot-scope="scope")
							el-checkbox(:disabled="!scope.row.flag" v-model="scope.row.hours[18]" )
					el-table-column(align="center" label="19:00" min-width="50px")
						template(slot-scope="scope")
							el-checkbox(:disabled="!scope.row.flag" v-model="scope.row.hours[19]" )
					el-table-column(align="center" label="20:00" min-width="50px")
						template(slot-scope="scope")
							el-checkbox(:disabled="!scope.row.flag" v-model="scope.row.hours[20]" )
					el-table-column(align="center" label="21:00" min-width="50px")
						template(slot-scope="scope")
							el-checkbox(:disabled="!scope.row.flag" v-model="scope.row.hours[21]" )
					el-table-column(align="center" label="22:00" min-width="50px")
						template(slot-scope="scope")
							el-checkbox(:disabled="!scope.row.flag" v-model="scope.row.hours[22]" )
					el-table-column(align="center" label="23:00" min-width="50px")
						template(slot-scope="scope")
							el-checkbox(:disabled="!scope.row.flag" v-model="scope.row.hours[23]" )
					el-table-column(align="center" label="Check All" fixed="right" width="100px")
						template(slot-scope="scope")
							el-button(size="mini" type="text" @click="checkAll(scope.row.label, true)" :disabled="!scope.row.flag") All
							el-divider(direction="vertical")
							el-button(size="mini" type="text" @click="checkAll(scope.row.label, false)" :disabled="!scope.row.flag") None


				el-divider

				el-row.text-left.mt-3(:gutter="12")
					h5 Brands And Integrations
					p Take into account that there might be a brand that has already been assigned with a control group. If you assign a brand to a new control group, the brand will be reassigned to the new control group. The same affects the integration as well.
					el-col(:xs="24", :sm="12")
						label.label-text-gray
							| Brands List
						el-form-item(:error="errors.brands_list.s ? errors.brands_list.m : ''" v-bind:class="{'is-invalid': errors.brands_list.s}")
							el-transfer.text-left.lead-box( filterable="" :data="options.brands" v-model="form.brands_list" :titles="['Available','Target']")

					el-col(:xs="24", :sm="12")
						label.label-text-gray
							| Integrations List
						el-form-item( :error="errors.integrations_list.s ? errors.integrations_list.m : ''" v-bind:class="{'is-invalid': errors.integrations_list.s}")
							el-transfer.text-left.lead-box( filterable="" :data="options.integrations" v-model="form.integrations_list" :titles="['Available','Target']")

				el-divider

				el-row.text-left.mt-5(:gutter="12")
					h5 Geos Assignment
					p The geos are assigned to the control group. If a lead is registered from a geo that is not assigned to the control group, the lead will be saved as a failed lead with the failed reason of "out of geo" and won't send it to the brand.
					el-col(:xs="24", :sm="12")
						label.label-text-gray
							| Geos List
						el-form-item( label-position="top" label-width="auto" :error="errors.geos_list.s ? errors.geos_list.m : ''" v-bind:class="{'is-invalid': errors.geos_list.s}")
							//br
							el-transfer.text-left.lead-box( filterable="" :data="options.countries" v-model="form.geos_list" :titles="['Available','Target']")

			el-divider
			el-row(:gutter="12" justify="between")
				el-button(@click="submit(true)" type="primary") Save
				el-button(type="danger" @click="$router.push({name: 'iframe-list'})") Cancel

</template>


<style lang="scss">

</style>


<script>
import {controlGroupWorkingHoursAll} from "@/struct";
import Vue from "vue";
import validator from "validator";

const $form = {
	name: '',
	notes: '',
	comments: '',
	geos_list: [],
	brands_list: [],
	integrations_list: [],
	working_hours_flag: false,
	working_hours_list: JSON.parse(JSON.stringify(controlGroupWorkingHoursAll))
};

const $error = {
	name: {m: '', s: false},
	notes: {m: '', s: false},
	comments: {m: '', s: false},
	geos_list: {m: '', s: false},
	brands_list: {m: '', s: false},
	integrations_list: {m: '', s: false}
};
export default {
	name: 'add-control-group',
	data() {
		return {
			type: 'Create',
			form: JSON.parse(JSON.stringify($form)),
			originalName: '',
			errors: JSON.parse(JSON.stringify($error)),
			options: {
				brands: [],
				integrations: [],
				countries: [],
			},
			original_name: ''
		}
	},
	async beforeRouteEnter(to, from, next) {

		if (to.name !== 'iframe-add-control-group' && to.name !== 'iframe-edit-control-group') return next();
		if (to.name === 'iframe-add-control-group' &&	!to.query.id) {
			const options = await Vue.apix.sendHttpRequest('GET', 'components/options', { filters: 'b46a2973-5f33-4d57-8bc2-3eb96bb26ad2,9e832481-2c11-4021-ba13-0dcc5487e3f7,49f06cec-46b0-4237-bf9a-d4d3969e5af6,' });

			next(vm => {
				vm.options = {};
				vm.options.countries = options.countries.map(e => Object.assign({}, { key: e.v, label: e.t }));
				vm.options.brands = options.brand.map(e => Object.assign({}, { key: e.v, label: e.t }));
				vm.options.integrations = options.integration_extra.map(e => Object.assign({}, { key: e.v, label: `${e.t} (${e.n})` }));
				vm.form = JSON.parse(JSON.stringify($form));
				vm.errors = JSON.parse(JSON.stringify($error));
				vm.type = 'Create';
			});
		} else if (to.name === 'iframe-edit-control-group' || (to.name === 'iframe-add-control-group' &&	to.query.id)) {
			let type = (to.name === 'iframe-add-control-group' && to.query) ? 'query' : 'params';
			const options = await Vue.apix.sendHttpRequest('GET', 'components/options', { filters: 'b46a2973-5f33-4d57-8bc2-3eb96bb26ad2,9e832481-2c11-4021-ba13-0dcc5487e3f7,49f06cec-46b0-4237-bf9a-d4d3969e5af6,' });
			const result = await Vue.apix.sendHttpRequest('GET', 'control-group/extra/' + to[type].id);

			next(vm => {
				vm.options = {};
				vm.options.countries = options.countries.map(e => Object.assign({}, { key: e.v, label: e.t }));
				vm.options.brands = options.brand.map(e => Object.assign({}, { key: e.v, label: e.t }));
				vm.options.integrations = options.integration_extra.map(e => Object.assign({}, { key: e.v, label: `${e.t} (${e.n})` }));
				vm.original_name = result.name;
				vm.type = (to.name === 'iframe-add-control-group' && to.query) ? 'Clone' : 'Edit';
				vm.form = JSON.parse(JSON.stringify($form));
				vm.form.name = result.name + (vm.type === 'Clone' ? '__copy' : '');
				vm.form.notes = result.notes;
				vm.form.comments = result.comments;
				vm.form.geos_list = result.geos;
				vm.form.brands_list = result.brands.map(e => e.id);
				vm.form.integrations_list = result.integrations.map(e => e.id);
				vm.form.working_hours_flag = result.working_hours_flag;
				vm.form.working_hours_list = result.working_hours_list;
				vm.errors = JSON.parse(JSON.stringify($error));
			});
		}
	},
	methods: {
		checkAll(day, value) {
			let index = this.form.working_hours_list.findIndex(e => e.label === day);
			if (index === -1) return;
			Object.values(this.form.working_hours_list[index].hours).forEach((e, i) => {
				this.form.working_hours_list[index].hours[i] = value;
			});
		},
		async submit() {
			const payload = this.preparePayload();
			if(!await this.validate()) return this.$notify.warning({ title: 'Validation error', message: 'Please, fix the validation errors in this form to proceed. You can see the error text in red font'});
			if(this.type === 'Create' || this.type === 'Clone') {
				let loader = this.$loading.show();
				this.$apix.sendHttpRequest('POST', 'control-group/create', payload)
						.then(res => {
							if (res.id) this.$router.push({name: 'iframe-list'})
							loader.hide()
						})
						.catch(err => {
							this.$notify.error('There was an issue. We are fixing the bug');
							console.error(err);
							loader.hide();
						});
			} else if(this.type === 'Edit' ) {
				let loader = this.$loading.show();
				this.$apix.sendHttpRequest('PUT', 'control-group/update/' + this.$route.params.id, payload)
						.then(res => {
							if (res.id) this.$router.push({name: 'iframe-list'})
							loader.hide()
						})
						.catch(err => {
							this.$notify.error('There was an issue. We are fixing the bug');
							console.error(err);
						});
			}
		},
		preparePayload() {
			const body = {};
			body.name = `${this.form.name}`;
			body.notes = `${this.form.notes}`;
			body.comments = `${this.form.comments}`;
			body.geos_list = [...this.form.geos_list];
			body.brands_list = [...this.form.brands_list];
			body.integrations_list = [...this.form.integrations_list];
			body.working_hours_list = [...this.form.working_hours_list];
			body.working_hours_flag = this.form.working_hours_flag;
			return body;
		},
		async validate() {
			this.errors = JSON.parse(JSON.stringify($error));

			let error = false;

			if (this.form.name === '') {
				this.errors.name = { s: true, m: 'name cannot be empty' };
				error = true;
			} else if (!validator.isAscii(this.form.name)) {
				this.errors.name = { s: true, m: 'name malformed' };
				error = true;
			} else {
				const check = await this.$apix.sendHttpRequest('GET', 'control-group/exists', {name: this.form.name, original: this.original_name});
				if (this.type === 'Create' && check.exists) {
					this.errors.name = { s: true, m: 'name already exists' };
					error = true;
				} else if (this.type === 'Edit' && this.form.name !== this.originalName && check.exists) {
					this.errors.name = { s: true, m: 'name already exists' };
					error = true;
				}
			}

			if (this.form.notes === '') {
				this.errors.notes.s = true;
				this.errors.notes.m = 'The notes field is required';
				error = true;
			}
			if (this.form.comments === '') {
				this.errors.comments.s = true;
				this.errors.comments.m = 'The comments field is required';
				error = true;
			}
			if (this.form.geos_list.length === 0) {
				this.errors.geos_list.s = true;
				this.errors.geos_list.m = 'The geos list is required';
				error = true;
			}/*
			if (this.form.brands_list.length === 0) {
				this.errors.brands_list.s = true;
				this.errors.brands_list.m = 'The brands list is required';
				error = true;
			}*/
			/*if (this.form.integrations_list.length === 0) {
				this.errors.integrations_list.s = true;
				this.errors.integrations_list.m = 'The integrations list is required';
				error = true;
			}*/
			return !error;
		}
	}
}
</script>